import { store } from "../index"
import { getModule, Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import ProxyFactory from "@/apiProxies/proxyFactory";
import { Logger } from "@/common/logger";
import { ChangeNumber } from "@/common/userProfile";
import { ChangePassword } from "@/common/userProfile";

@Module({ store, name: 'userProfileModule', namespaced: true, dynamic: true })
export class UserProfileModule extends VuexModule {

    private _phoneChanging = false;
    private _phoneVerifing = false;
    private _emailVerifing = false;

    get phoneChanging(): boolean {
        return this._phoneChanging;
    }

    get phoneVerifing(): boolean {
        return this._phoneVerifing;
    }

    get emailVerifing(): boolean {
        return this._emailVerifing;
    }

    @Mutation
    setPhoneChanging(value: boolean): void {
        this._phoneChanging = value;
    }

    @Mutation
    setPhoneVerifing(value: boolean): void {
        this._phoneVerifing = value;
    }

    @Mutation
    setEmailVerifing(value: boolean): void {
        this._emailVerifing = value;
    }

    @Action({ rawError: true })
    async changePassword(value: ChangePassword): Promise<void> {
        try {
            const proxy = await ProxyFactory.createUserProfileManagerProxy();

            await proxy.changePassword(value.currentPassword, value.newPassword);
        }
        catch (exc) {
            Logger.logError("changePassword FAILED!", exc)
            throw exc;
        }
    }

    @Action({ rawError: true })
    async changeNumber(value: ChangeNumber): Promise<void> {
        if (!this.phoneChanging) {
            try {
                this.setPhoneChanging(true);

                const proxy = await ProxyFactory.createUserProfileManagerProxy();
                await proxy.changeNumber(value.phone, value.code);
            }
            catch (exc) {
                Logger.logError("changeNumber FAILED!", exc)
                throw exc;
            } finally {
                this.setPhoneChanging(false);
            }
        }
    }

    @Action({ rawError: true })
    async verifyPhone(phone: string): Promise<void> {
        try {
            const proxy = await ProxyFactory.createUserProfileManagerProxy();
            await proxy.verifyPhone(phone);
            this.setPhoneVerifing(true);
        }
        catch (exc) {
            Logger.logError("verifyPhone FAILED!", exc)
            throw exc;
        }
    }

    @Action({ rawError: true })
    async cancelVerification(): Promise<void> {
        try {
            const proxy = await ProxyFactory.createUserProfileManagerProxy();
            await proxy.cancelPhoneVerifing();
            this.setPhoneVerifing(false);
        }
        catch (exc) {
            Logger.logError("cancelVerification FAILED!", exc)
            throw exc;
        }
    }

    @Action({ rawError: true })
    async checkEmail(email: string): Promise<void> {
        const proxy = await ProxyFactory.createUserProfileManagerProxy()

        await proxy.checkEmail(email)

        this.setEmailVerifing(true)
    }

    @Action({ rawError: true })
    async changeEmail(body: { email: string, code: string }): Promise<void> {
        const proxy = await ProxyFactory.createUserProfileManagerProxy()

        await proxy.changeEmail(body.email, body.code)
    }
}

export const userProfileModule = getModule(UserProfileModule);